<template>
	<div class="address" ref="address" :style="{'overflow': (showIndexBar? 'hidden':'auto')}">
		<van-search v-model="value" placeholder="请输入城市" @clear="showIndexBar=false" @update:model-value="filterCity" />
		<!-- <div class="citys">
			<span class="hot-citys-title">
				当前位置
			</span>
			<div class="hot-citys">

				<div class="hot-city" @click="seleteCity(currentLocation)"><span>{{currentLocation.name}}</span></div>
			</div>
		</div> -->
		<div class="citys" v-if="visitCity.length>0">
			<span class="hot-citys-title">
				最近访问
			</span>
			<div class="hot-citys">

				<div v-for="item in visitCity.slice(0,3)" class="hot-city" @click="seleteCity(item)">
					<span>{{item.name}}</span></div>
			</div>
		</div>
		<div class="citys">
			<span class="hot-citys-title">
				热门城市
			</span>
			<div class="hot-citys">

				<div v-for="item in hotCity" class="hot-city" @click="seleteCity(item)"><span>{{item.name}}</span></div>
			</div>
		</div>
		<van-index-bar>

			<div v-for="(v,i) in citys.city">
				<van-index-anchor :index="v.initial" />
				<van-cell :title="city.name" v-for="city in v.list" @click="seleteCity(city)" />
			</div>



		</van-index-bar>

		<div class="show-result" v-if="showIndexBar">

			<van-cell-group>
				<van-cell :title="item.name" v-for="item in resultList" @click="seleteCity(item)" />

			</van-cell-group>
		</div>
	</div>
</template>

<script>
	import {
		ref
	} from 'vue';
	import city_list from '../../utils/city1.json'
	export default {
		setup() {
			const value = ref('');
			return {
				value
			};
		},
		data() {
			return {
				currentLocation: this.$store.state.app.city,
				citys: city_list,
				visitCity: [],
				// visitCity: [{
				// 		name: '上海',
				// 		"parent_code": "310000",
				// 		"lng": "121.472641",
				// 		"lat": "31.231707",
				// 	},
				// 	{
				// 		name: '深圳',
				// 		"lng": "114.085945",
				// 		"lat": "22.547001",
				// 		"code": "440300",
				// 	},
				// 	{
				// 		name: '北京',
				// 		"lng": "116.405289",
				// 		"lat": "39.904987",
				// 		"code": "110100",
				// 	},
				// ],
				hotCity: [{
						"id": 107,
						"name": "上海市",
						"code": "310100",
						"parent_code": "310000",
						"level": 2,
						"lng": "121.472641",
						"lat": "31.231707",
						"pinyin": "shanghaishi"
					},
					{
						"id": 232,
						"name": "深圳市",
						"code": "440300",
						"parent_code": "440000",
						"level": 2,
						"lng": "114.085945",
						"lat": "22.547001",
						"pinyin": "shenzhenshi"
					},
					{
						"id": 35,
						"name": "北京市",
						"code": "110100",
						"parent_code": "110000",
						"level": 2,
						"lng": "116.405289",
						"lat": "39.904987",
						"pinyin": "beijingshi"
					},
					{
						"id": 230,
						"name": "广州市",
						"code": "440100",
						"parent_code": "440000",
						"level": 2,
						"lng": "113.28064",
						"lat": "23.125177",
						"pinyin": "guangzhoushi"
					},
					{
						"id": 272,
						"name": "成都市",
						"code": "510100",
						"parent_code": "510000",
						"level": 2,
						"lng": "104.065735",
						"lat": "30.659462",
						"pinyin": "chengdushi"
					},
					{
						"id": 121,
						"name": "杭州市",
						"code": "330100",
						"parent_code": "330000",
						"level": 2,
						"lng": "120.15358",
						"lat": "30.287458",
						"pinyin": "hangzhoushi"
					},
					{
						"id": 108,
						"name": "南京市",
						"code": "320100",
						"parent_code": "320000",
						"level": 2,
						"lng": "118.76741",
						"lat": "32.041546",
						"pinyin": "nanjingshi"
					},
					{
						"id": 270,
						"name": "重庆市",
						"code": "500100",
						"parent_code": "500000",
						"level": 2,
						"lng": "106.504959",
						"lat": "29.533155",
						"pinyin": "chongqingshi"
					},
					{
						"id": 246,
						"name": "东莞市",
						"code": "441900",
						"parent_code": "440000",
						"level": 2,
						"lng": "113.746262",
						"lat": "23.046238",
						"pinyin": "dongguanshi"
					},
				],
				showIndexBar: false,

				resultList: [],
				visitCitySet: []
			}

		},

		mounted() {
			let visitCity
			if (localStorage.getItem('visitCity')) {
				visitCity = localStorage.getItem('visitCity')
				this.visitCity = JSON.parse(visitCity)				
			}
		},
		methods: {
			
			//城市选择
			seleteCity(item) {	console.log(this.visitCity)
						 				
				this.visitCity.unshift(item)				
				var hash = {};
				let visitCityNew= this.visitCity.reduce(function(item, next) {
				    hash[next.name] ? '' : hash[next.name] = true && item.push(next);
				    return item
				}, [])								
				this.visitCity = visitCityNew	
							console.log(this.visitCity)
				localStorage.setItem('visitCity', JSON.stringify(this.visitCity))
				this.$store.commit('LOCATION_CITY', this.visitCity)		
				this.$router.go(-1)
			},
			//模糊搜索
			filterCity(val) {
				console.log(val)
				this.resultList = []
				if (val.trim()) {
					this.showIndexBar = true
					let res = city_list.city.filter(i => {
						console.log(i)
						i.list.forEach((value, index) => {
							if (value.name.indexOf(val) !== -1) {
								this.resultList.push(value)
							}
						})
					})

				} else {
					this.showIndexBar = false
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.address {
		height: 100%;

		.van-index-bar::v-deep(.van-index-bar__sidebar) {
			top: 60%;
		}

		.citys {
			padding: 10px 15px;

			.hot-citys-title {
				color: #999999;
				font-size: 16px;

			}

			.hot-citys {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				box-sizing: border-box;

				.hot-city {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #FFFFFF;
					border: solid 1px #F6F6F6;
					width: 30%;
					padding: 5px;
					box-sizing: border-box;
					margin-top: 10px;
					margin-left: 10px;

					span {
						font-size: 14px;
					}
				}
			}
		}

		.show-result {
			position: fixed;
			z-index: 100;
			width: 100%;
			top: 54px;
			height: 100%;
			background-color: #FFFFFF;
			overflow-y: scroll;
		}
	}
</style>
